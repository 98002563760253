* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.app {
  display: flex;
  flex-direction: column;
  width: 100dvw;
  height: 100dvh;
  align-items: center;
  justify-content: flex-start;
  font-family: "Helvetica", sans-serif;
  font-size: 1rem;
  padding: 0 1rem;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
}

header {
  position: sticky;
  top: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  width: 100%;
  z-index: 100;
  font-size: 2rem;
  font-weight: bold;
  border-bottom: 1px solid black;
  padding-bottom: 0.125rem;
  background-color: white;
}

header a {
  font-size: 2rem;
  color: blue;
  text-decoration: underline;
  width: fit-content;
}

.fr0gg-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0.75rem 0.75rem;
  width: 100%;
  height: fit-content;
  padding: 0.75rem 0rem;
}

.fr0gg {
  border: 1px solid black;
  background: lightgray;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fr0gg::before {
  content: '';
  display: block;
  padding-top: 100%
}

.fr0gg img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 0.5s ease-in-out;
}

.not-loaded {
  opacity: 0;

}

.loaded {
  opacity: 1;

}

.image-loading {
  opacity: 0.5;
}

.info {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.25rem;
  /* border-top: 1px solid black;
  border-bottom: 1px solid black; */
  /* border: 1px solid black; */
  background: green;
  margin-top: 0.5rem;
}

.birthday {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  /* border: 1px solid white; */
  padding: 0rem 0.5rem 0 0;
  border-radius: 0.125rem;
  cursor: pointer;
}

.copy-prompt {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  padding: 0rem 0.25rem 0.125rem;
  border-radius: 0.125rem;
  cursor: pointer;
  background: #ECF0F1;
}

.checkmark {
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.8rem;
}

.checked {
  color: orangered;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  width: 100dvw;
  font-size: 4rem;
  animation: loading 400ms infinite alternate-reverse;
}

@keyframes loading {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.125);
  }
}

.footer {
  position: relative;
  display: flex;
  width: calc(100% - 2rem);
  width: 100%;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem 0rem;
  margin: 0 1rem;
  font-size: 0.8rem;
  background: white;
  border-top: 1px solid black;
}

/* The scrollbar itself */
::-webkit-scrollbar {
  width: 0;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: white;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media screen and (max-width: 970px) {
  .fr0gg-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 800px) {
  .fr0gg-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .fr0gg-container {
    grid-template-columns: repeat(2, 1fr);
  }
}